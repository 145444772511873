<template>
<div :class="cardMainDivClass">
    <feather-icon :icon="mainDivFeatherIcon" :class="mainDivFeatherIconClass"></feather-icon>
    <div class="review-title pl-6">
        <h4 :class="h4Class"><span :class="h4SpanClass">{{statEndValue}}</span>{{cardTitle}}
            <vx-tooltip :title="cardTitle" color="secondary" :text="titleToolTipText" v-if="titleToolTipText">
                <vs-button :color="titleToolTipButtonColor" type="flat" icon-pack="feather" icon="icon-help-circle" class="p-0 help-btn"></vs-button>
            </vx-tooltip>
        </h4>
        <p v-if="oneInquiryEvery" class="text-white">{{oneInquiryEvery}}</p>
        <p :class="pClass">{{previousPeriod}}
            <vx-tooltip :title="cardTitle" color="secondary" :text="previousPeriodToolTipText" v-if="previousPeriodToolTipText">
                <vs-button :color="previousPeriodToolTipButtonColor" type="flat" icon-pack="feather" icon="icon-help-circle" class="p-0 help-btn"></vs-button>
            </vx-tooltip>
        </p>
    </div>
    <div class="up-down-value">
        <feather-icon :icon="iconUpDown(statEndValue, statStartValue, statChangePercent, positiveNegative)" :class="upDownFeatherIconClass(statEndValue, statStartValue, statChangePercent, positiveNegative)"></feather-icon>
        <span :class="textColorUpDown(statEndValue, statStartValue, statChangePercent, positiveNegative)">{{statChangePercent}}</span>
    </div>
</div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";

export default {
    props: ['cardMainDivClass', 'mainDivFeatherIcon', 'mainDivFeatherIconClass', 'h4Class', 'h4SpanClass', 'statEndValue', 'cardTitle', 'titleToolTipText', 'titleToolTipButtonColor', 'pClass', 'previousPeriod', 'previousPeriodToolTipText', 'previousPeriodToolTipButtonColor', 'statChangePercent', 'statStartValue', 'positiveNegative', 'oneInquiryEvery'],
    components: {
        VxCard
    },
    methods: {
        iconUpDown(value1, value2, value, positiveNegative = false) {
            if (value) {
                if (!positiveNegative) {
                    if (value.substring(0, 2) > 0) {
                        return 'ArrowUpIcon';
                    } else {
                        if (value == '--' || value1 == value2) {
                            return '';
                        } else {
                            return 'ArrowDownIcon';
                        }
                    }
                } else {
                    if (value.substring(0, 2) > 0) {
                        if (value == '--' || value1 == value2) {
                            return '';
                        } else {
                            return 'ArrowDownIcon';
                        }
                    } else {
                        if (value1 == value2) {
                            return '';
                        } else {
                            return 'ArrowUpIcon';
                        }
                    }
                }
            }
        },
        textColorUpDown(value1, value2, value, positiveNegative = false) {
            if (value) {
                if (!positiveNegative) {
                    if (value.substring(0, 2) > 0) {
                        return 'text-success';
                    } else {
                        if (value == '--' || value1 == value2) {
                            return 'text-primary';
                        } else {
                            return 'text-danger';
                        }
                    }
                } else {
                    if (value.substring(0, 2) > 0) {
                        if (value == '--' || value1 == value2) {
                            return 'text-primary';
                        } else {
                            return 'text-danger';
                        }
                    } else {
                        if (value1 == value2) {
                            return 'text-primary';
                        } else {
                            return 'text-success';
                        }
                    }
                }
            }
        },
        upDownFeatherIconClass(value1, value2, value, positiveNegative = false) {
            if (value) {
                if (!positiveNegative) {
                    if (value.substring(0, 2) > 0) {
                        return 'text-success';
                    } else {
                        if (value == '--' || value1 == value2) {
                            return 'text-primary';
                        } else {
                            return 'text-danger';
                        }
                    }
                } else {
                    if (value.substring(0, 2) > 0) {
                        if (value == '--' || value1 == value2) {
                            return 'text-primary';
                        } else {
                            return 'text-danger';
                        }
                    } else {
                        if (value1 == value2) {
                            return 'text-primary';
                        } else {
                            return 'text-success';
                        }
                    }
                }
            }
        }
    }

}
</script>

<style>

</style>
