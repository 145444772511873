<template>
  <div class="marketing-dashboard mt-8">
    <div class="vx-row">
      <!-- Email Stats -->
      <div class="vx-col lg:w-1/2 col-cus-12 mb-8">
        <vx-card class="total-review h-full">
          <div class="card-title">
            <h2>Email Stats</h2>
          </div>
          <div class="vx-row">
            <div class="vx-col md:w-1/3 w-full mb-4 md:order-1 order-2">
              <dashboard-single-card
                :cardMainDivClass="'review-part bg-primary-gradient'"
                :mainDivFeatherIconClass="'text-white'"
                :h4Class="'text-white'"
                :h4SpanClass="'review-no text-white'"
                :statEndValue="emailsSent.StatStartValue"
                :cardTitle="'Emails Sent'"
                :pClass="'text-white'"
                :previousPeriod="'Previous Period ' + emailsSent.StatEndValue"
                :previousPeriodToolTipButtonColor="'white'"
                :statChangePercent="emailsSent.StatChangePercent"
                :statStartValue="emailsSent.StatStartValue"
              ></dashboard-single-card>
              <!--<div class="review-part bg-primary-gradient">
                            <div class="review-title pl-6">
                                <h4 class="text-white"><span class="review-no text-white">{{ emailsSent.StatEndValue }}</span> Emails Sent </h4>
                                <p class="text-white">Previous Period {{ emailsSent.StatStartValue }}</p>
                            </div>
                            <div class="up-down-value">
                                <feather-icon icon="ArrowUpIcon" class="text-white"></feather-icon>
                                <span class="num-value text-white">{{ emailsSent.StatChangePercent+"%" }}</span>
                            </div>
                        </div>-->
            </div>
            <div class="vx-col md:w-2/3 w-full md:order-2 order-1">
              <vue-apex-charts
                type="line"
                height="175"
                :options="emailStats.chartOptions"
                :series="emailStats.series"
              ></vue-apex-charts>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-1/3 w-full sm:mt-0 mt-4">
              <dashboard-single-card
                :cardMainDivClass="'review-part'"
                :mainDivFeatherIconClass="'text-primary'"
                :h4Class="''"
                :h4SpanClass="'review-no text-primary'"
                :statEndValue="emailsDelivered.StatStartValue"
                :cardTitle="'Emails Deliverd'"
                :pClass="'gray'"
                :previousPeriod="'Previous Period ' + emailsDelivered.StatEndValue"
                :statChangePercent="emailsDelivered.StatChangePercent"
                :statStartValue="emailsDelivered.StatStartValue"
              ></dashboard-single-card>
              <!--<div class="review-part">
                            <div class="review-title pl-6">
                                <h4><span class="review-no text-primary">{{ emailsDelivered.StatEndValue }}</span>Emails Deliverd </h4>
                                <p>Previous Period {{ emailsDelivered.StatStartValue }} </p>
                            </div>
                            <div class="up-down-value">
                                <feather-icon icon="ArrowUpIcon" class="text-primary"></feather-icon>
                                <span class="num-value text-primary">{{ emailsDelivered.StatChangePercent+"%" }}</span>
                            </div>
                        </div>-->
            </div>
            <div class="vx-col sm:w-1/3 w-full sm:mt-0 mt-4">
              <dashboard-single-card
                :cardMainDivClass="'review-part'"
                :mainDivFeatherIconClass="'text-primary'"
                :h4Class="''"
                :h4SpanClass="'review-no text-primary'"
                :statEndValue="emailsOpened.StatStartValue"
                :cardTitle="'Emails Opened'"
                :pClass="'gray'"
                :previousPeriod="'Previous Period ' + emailsOpened.StatEndValue"
                :statChangePercent="emailsOpened.StatChangePercent"
                :statStartValue="emailsOpened.StatStartValue"
              ></dashboard-single-card>
              <!--<div class="review-part">
                            <div class="review-title pl-6">
                                <h4><span class="review-no text-primary">{{ emailsOpened.StatEndValue }}</span>Emails Opened </h4>
                                <p>Previous Period {{ emailsOpened.StatStartValue }} </p>
                            </div>
                            <div class="up-down-value">
                                <feather-icon icon="ArrowUpIcon" class="text-primary"></feather-icon>
                                <span class="num-value text-primary">{{ emailsOpened.StatChangePercent+"%" }}</span>
                            </div>
                        </div>-->
            </div>
            <div class="vx-col sm:w-1/3 w-full sm:mt-0 mt-4">
              <dashboard-single-card
                :cardMainDivClass="'review-part'"
                :mainDivFeatherIconClass="'text-primary'"
                :h4Class="''"
                :h4SpanClass="'review-no text-primary'"
                :statEndValue="emailsClicked.StatStartValue"
                :cardTitle="'Emails Clicked'"
                :pClass="'gray'"
                :previousPeriod="'Previous Period ' + emailsClicked.StatEndValue"
                :statChangePercent="emailsClicked.StatChangePercent"
                :statStartValue="emailsClicked.StatStartValue"
              ></dashboard-single-card>
              <!--<div class="review-part">
                            <div class="review-title pl-6">
                                <h4><span class="review-no text-primary">{{ emailsClicked.StatEndValue }}</span>Emails Clicked </h4>
                                <p>Previous Period {{ emailsClicked.StatStartValue }} </p>
                            </div>
                            <div class="up-down-value">
                                <feather-icon icon="ArrowUpIcon" class="text-primary"></feather-icon>
                                <span class="num-value text-primary">{{ emailsClicked.StatChangePercent+"%" }}</span>
                            </div>
                        </div>-->
            </div>
          </div>
        </vx-card>
      </div>
      <!-- Contact Stats -->
      <div class="vx-col lg:w-1/2 col-cus-12 mb-8">
        <vx-card class="total-review h-full">
          <div class="card-title">
            <h2>Contact Stats</h2>
          </div>
          <div class="vx-row">
            <div class="vx-col md:w-1/3 w-full mb-4 md:order-1 order-2">
              <dashboard-single-card
                :cardMainDivClass="'review-part bg-primary-gradient'"
                :mainDivFeatherIconClass="'text-white'"
                :h4Class="'text-white'"
                :h4SpanClass="'review-no text-white'"
                :statEndValue="contacts.StatStartValue"
                :cardTitle="'Contacts'"
                :pClass="'text-white'"
                :previousPeriod="'Previous Period ' + contacts.StatEndValue"
                :previousPeriodToolTipButtonColor="'white'"
                :statChangePercent="contacts.StatChangePercent"
                :statStartValue="contacts.StatStartValue"
              ></dashboard-single-card>
              <!--<div class="review-part bg-primary-gradient">
                            <div class="review-title pl-6">
                                <h4 class="text-white"><span class="review-no text-white">{{ contacts.StatEndValue }}</span> Total Contacts </h4>
                                <p class="text-white">Previous Period {{ contacts.StatStartValue }}</p>
                            </div>
                            <div class="up-down-value">
                                <feather-icon icon="ArrowUpIcon" class="text-white"></feather-icon>
                                <span class="num-value text-white">{{ contacts.StatChangePercent+"%" }}</span>
                            </div>
                        </div>-->
            </div>
            <div class="vx-col md:w-2/3 w-full md:order-2 order-1">
              <vue-apex-charts
                type="line"
                height="175"
                :options="contactStats.chartOptions"
                :series="contactStats.series"
              ></vue-apex-charts>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-1/3 w-full sm:mt-0 mt-4">
              <dashboard-single-card
                :cardMainDivClass="'review-part'"
                :mainDivFeatherIconClass="'text-primary'"
                :h4Class="''"
                :h4SpanClass="'review-no text-primary'"
                :statEndValue="newContacts.StatStartValue"
                :cardTitle="'New Contacts'"
                :pClass="'gray'"
                :previousPeriod="'Previous Period ' + newContacts.StatEndValue"
                :statChangePercent="newContacts.StatChangePercent"
                :statStartValue="newContacts.StatStartValue"
              ></dashboard-single-card>
              <!-- <div class="review-part">
                            <div class="review-title pl-6">
                                <h4><span class="review-no text-primary">{{ newContacts.StatEndValue }}</span>New Contacts </h4>
                                <p>Previous Period {{ newContacts.StatStartValue}} </p>
                            </div>
                            <div class="up-down-value">
                                <feather-icon icon="ArrowUpIcon" class="text-primary"></feather-icon>
                                <span class="num-value text-primary">{{ newContacts.StatChangePercent+"%" }}</span>
                            </div>
                        </div>-->
            </div>
            <div class="vx-col sm:w-1/3 w-full sm:mt-0 mt-4">
              <dashboard-single-card
                :cardMainDivClass="'review-part'"
                :mainDivFeatherIconClass="'text-primary'"
                :h4Class="''"
                :h4SpanClass="'review-no text-primary'"
                :statEndValue="subscribed.StatStartValue"
                :cardTitle="'Subscribed Contacts'"
                :pClass="'gray'"
                :previousPeriod="'Previous Period ' + subscribed.StatEndValue"
                :statChangePercent="subscribed.StatChangePercent"
                :statStartValue="subscribed.StatStartValue"
              ></dashboard-single-card>
              <!--<div class="review-part">
                            <div class="review-title pl-6">
                                <h4><span class="review-no text-primary">{{ subscribed.StatEndValue }}</span>Subscribed Contacts </h4>
                                <p>Previous Period {{ subscribed.StatStartValue }} </p>
                            </div>
                            <div class="up-down-value">
                                <feather-icon icon="ArrowUpIcon" class="text-primary"></feather-icon>
                                <span class="num-value text-primary">{{ subscribed.StatChangePercent+"%" }}</span>
                            </div>
                        </div>-->
            </div>
            <div class="vx-col sm:w-1/3 w-full sm:mt-0 mt-4">
              <dashboard-single-card
                :cardMainDivClass="'review-part'"
                :positiveNegative="true"
                :mainDivFeatherIconClass="'text-primary'"
                :h4Class="''"
                :h4SpanClass="'review-no text-primary'"
                :statEndValue="unSubscribed.StatStartValue"
                :cardTitle="'Unsbscribed Contacts'"
                :pClass="'gray'"
                :previousPeriod="'Previous Period ' + unSubscribed.StatEndValue"
                :statChangePercent="unSubscribed.StatChangePercent"
                :statStartValue="unSubscribed.StatStartValue"
              ></dashboard-single-card>
              <!--<div class="review-part">
                            <div class="review-title pl-6">
                                <h4><span class="review-no text-primary">{{ unSubscribed.StatEndValue }}</span>Unsbscribed Contacts </h4>
                                <p>Previous Period {{ unSubscribed.StatStartValue }} </p>
                            </div>
                            <div class="up-down-value">
                                <feather-icon icon="ArrowUpIcon" class="text-primary"></feather-icon>
                                <span class="num-value text-primary">{{ unSubscribed.StatChangePercent+"%" }}</span>
                            </div>
                        </div>-->
            </div>
          </div>
        </vx-card>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vx-card>
          <div class="vx-row">
            <!-- Campaign -->
            <div class="vx-col lg:w-1/2 w-full">
              <div class="marketing-tabs">
                <div class="card-title">
                  <h2>Campaign</h2>
                </div>
                <div
                  class="tab-row"
                  v-for="(campaign, index) in campaigns.slice(0, 4)"
                  :key="index"
                >
                  <span class="camp-icon">
                    <img src="../../assets/images/email-marketing.svg"/>
                  </span>
                  <div class="campaign-title">
                    <h4>{{ campaign.Campaign_Name }}</h4>
                    <span>
                      <small>{{ campaign.Number_Of_Opens }} opens</small>
                      <small>{{ campaign.Number_Of_Clicks }} clicks</small>
                    </span>
                  </div>
                  <vs-button
                    radius
                    type="filled"
                    class="bg-actionbutton small-btn"
                    @click="getCampaignOverviewReport(campaign.Campaign_Id)"
                    >{{ LabelConstant.buttonLabelViewReport }}</vs-button
                  >
                </div>
                <router-link class="view-camp" :to="{ path: '/email-marketing/campaigns/uid/' + $route.params.id,}">
                  {{ LabelConstant.linkLabelViewAll }}
                </router-link>
                <!-- <a :href="'/email-marketing/campaigns/uid/0'" class="view-camp">View All</a> -->
              </div>
            </div>
            <!-- Tags -->
            <div class="vx-col lg:w-1/2 w-full lg:mt-0 mt-8">
              <div class="marketing-tabs">
                <div class="card-title">
                  <h2>Tags</h2>
                </div>
                <div class="tab-row" v-for="(tag, index) in tags" :key="index">
                  <span class="camp-icon">
                    <img src="../../assets/images/tag.svg" />
                  </span>
                  <div class="campaign-title">
                    <h4>{{ tag.Tag_Name }}</h4>
                    <span>
                      <small>{{ tag.Contact_Count }}</small>
                    </span>
                  </div>
                  <vs-button
                    radius
                    type="filled"
                    class="bg-actionbutton small-btn"
                    @click="redirectToContactsWithSelectedTag(tag.Tag_Id)"
                    >{{ LabelConstant.buttonLabelView }}</vs-button
                  >
                </div>
                <vs-button
                  radius
                  type="filled"
                  color="primary"
                  icon-pack="feather"
                  icon="icon-plus"
                  class="small-btn float-left ml-4 mt-2"
                  >{{ LabelConstant.buttonLabelAddaTag }}</vs-button
                >
                <a href="#" class="view-camp">{{ LabelConstant.linkLabelViewAll }}</a>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
    <!-- View Report Popup -->
    <vs-popup
      class="popup-width close-open"
      title="View Report"
      :active.sync="CampaignViewReportActive"
    >
      <vs-button
        @click="closeViewReport()"
        color="secondary"
        type="filled"
        class="mb-8 float-right close-popup-btn"
        >{{ LabelConstant.buttonLabelClose }}</vs-button
      >
      <!-- Report Details -->
      <div class="campaign-view-report" v-if="viewReportDetails">
        <vs-tabs class="contact-tab" v-model="tabActive"> 
          <!-- Overview -->
          <vs-tab label="Overview" icon-pack="feather" icon="icon-eye">
            <!-- Common info -->
            <div class="vx-row">
              <div class="vx-col w-full">
                <div class="customers-info mt-5">
                  <h2>
                    {{ viewReportDetails.Campaign_Name }}
                    <span>{{ viewReportDetails.Contact_Count }} Recipents</span>
                  </h2>
                  <div class="newslatter-data">
                    <ul>
                      <li>Subject: {{ viewReportDetails.Subject }}</li>
                      <li>Segment: {{ viewReportDetails.Segment }}</li>
                      <li>
                        Delivered: {{ viewReportDetails.Date_Sent_Long | templatEditedDateTime }}
                      </li>
                    </ul>
                    <vs-button
                      class="small-btn bg-actionbutton"
                      type="filled"
                      @click="viewEmailDetails(viewReportDetails.Campaign_Id)"
                      >{{ LabelConstant.buttonLabelViewEmail }}</vs-button
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- Cards Statistics: Sent, Opened, Clicked, Unsubscribed-->
            <div class="vx-row">
              <div class="vx-col mb-8 md:w-1/2 w-full">
                <single-card
                  icon="SendIcon"
                  smallTitle="Sent"
                  bgcolor="bg-first-gradient"
                  maintextcolor="text-first"
                  bgimg="email-marketing.svg"
                  emitTagChange="Sent To"
                  @changeTab="changeTab"
                  :totalValue="viewReportDetails?viewReportDetails.Total_Sent:0"
                >
                </single-card>
              </div>
              <div class="vx-col mb-8 md:w-1/2 w-full">
                <single-card
                  icon="ExternalLinkIcon"
                  smallTitle="Opened"
                  bgcolor="bg-second-gradient"
                  maintextcolor="text-second"
                  bgimg="email-marketing.svg"
                  emitTagChange="Opened"
                  @changeTab="changeTab"
                  :totalValue="viewReportDetails?viewReportDetails.Open_Count:0"
                >
                </single-card>
              </div>
              <div class="vx-col mb-8 md:w-1/2 w-full">
                <single-card
                  icon="MousePointerIcon"
                  smallTitle="Clicked"
                  bgcolor="bg-third-gradient"
                  maintextcolor="text-third"
                  bgimg="email-marketing.svg"
                  emitTagChange="Clicked"
                  @changeTab="changeTab"
                  :totalValue="viewReportDetails?viewReportDetails.Click_Count:0"
                >
                </single-card>
              </div>
              <div class="vx-col mb-8 md:w-1/2 w-full">
                <single-card
                  icon="BellOffIcon"
                  smallTitle="Unsubscribed"
                  bgcolor="bg-fourth-gradient"
                  maintextcolor="text-fourth"
                  bgimg="email-marketing.svg"
                  emitTagChange="Unsubscribed"
                  @changeTab="changeTab"
                  :totalValue="viewReportDetails?viewReportDetails.Unsubscribed_Count:0"
                >
                </single-card>
              </div>
            </div>
            <!-- 24 hours performance -->
            <div class="vx-row mb-8">
              <div class="vx-col w-full">
                <vx-card>
                  <div class="card-title">
                    <h2>24-hour performance</h2>
                  </div>
                  <vue-apex-charts
                    type="line"
                    height="350"
                    :options="lineChartSimple.chartOptions"
                    :series="lineChartSimple.series"
                  ></vue-apex-charts>
                </vx-card>
              </div>
            </div>
            <!-- Top links clicked and Subscribers with most commons-->
            <div class="vx-row">
              <!-- Top links clicked -->
              <div class="vx-col lg:w-1/2 w-full mb-8">
                <vx-card class="main-list">
                  <div class="card-title"><h2>Top Links clicked</h2></div>
                  <ul class="latest-list">
                    <li v-for="(toplink, index) in viewReportDetails.Top_Links_Clicked" :key="index">
                      <a href="#">{{ toplink.Key }}</a>
                      <span>{{ toplink.Value }}</span>
                    </li>
                  </ul>
                  <vs-button
                    class="small-btn bg-actionbutton"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-eye"
                    v-if="viewReportDetails.Subscribers_With_Most_Open.length > 0 && limitedTopLinks == 5"
                    @click="showMoreTopLinks()"
                    >{{ LabelConstant.buttonLabelShowMore }}</vs-button
                  >
                  <vs-button
                    class="small-btn bg-actionbutton"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-eye"
                    v-if="viewReportDetails.Subscribers_With_Most_Open.length > 0 && limitedTopLinks > 5"
                    @click="showMoreTopLinks()"
                    >{{ LabelConstant.buttonLabelShowLess }}</vs-button
                  >
                </vx-card>
              </div>
              <!-- Subscribers with most commons-->
              <div class="vx-col lg:w-1/2 w-full mb-8">
                <vx-card class="main-list">
                  <div class="card-title">
                    <h2>Subscribers with most opens</h2>
                  </div>
                  <ul class="latest-list">
                    <li v-for="(subscribers, index) in viewReportDetails.Subscribers_With_Most_Open" :key="index">
                      <a href="#">{{ subscribers.Key }}</a>
                      <span>{{ subscribers.Value }}</span>
                    </li>
                  </ul>
                  <vs-button
                    class="small-btn bg-actionbutton"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-eye"
                    v-if="viewReportDetails.Subscribers_With_Most_Open.length > 0 && limitedSubcribers == 5"
                    @click="showMoreSubcriber()"
                    >{{ LabelConstant.buttonLabelShowMore }}</vs-button
                  >
                  <vs-button
                    class="small-btn bg-actionbutton"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-eye"
                    v-if="viewReportDetails.Subscribers_With_Most_Open.length > 0 && limitedSubcribers > 5"
                    @click="showMoreSubcriber()"
                    >{{ LabelConstant.buttonLabelShowLess }}</vs-button
                  >
                </vx-card>
              </div>
            </div>
            <!-- IF A/B Test Result -->
            <div class="vx-row" v-if="viewReportDetails.Campaign_Type == 'AB'">
              <div class="vx-col w-full">
                <div class="card-title pb-2"><h2>A/B Test result</h2></div>
              </div>
              <div
                class="vx-col w-full lg:w-1/2"
                v-if="senderList.length > 0"
                v-for="(sender, index) in senderList"
                :key="index"
              >
                <div class="test-result">
                  <!-- <span class="letter-box bg-first-gradient">A</span> -->
                  <div class="campaign-result">
                    <h3>Send this campaign "From"</h3>
                    <p>
                      {{ sender.Sender_Test.From_Name + " " + sender.Sender_Test.From_Email }}
                    </p>
                    <address>
                      {{ sender.Sender_Test.Address1 }} <br />
                      {{ sender.Sender_Test.City + ", " + sender.Sender_Test.State + " " + sender.Sender_Test.Zip }}
                    </address>
                    <div class="campaign-chart vx-row">
                      <div class="w-full sm:w-1/2">
                        <vue-apex-charts
                          type="radialBar"
                          height="100"
                          :options="sender.Open_Rate_Chart.chartOptions"
                          :series="sender.Open_Rate_Chart.series"
                        ></vue-apex-charts>
                        <h4>Open Rate</h4>
                      </div>
                      <div class="w-full sm:w-1/2">
                        <vue-apex-charts
                          type="radialBar"
                          height="100"
                          :options="sender.Click_Rate_Chart.chartOptions"
                          :series="sender.Click_Rate_Chart.series"
                        ></vue-apex-charts>
                        <h4>Click Rate</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="vx-col w-full">
                <div
                  class="vx-row w-full mt-3 vx-card sm:p-5 p-4 mr-0"
                  v-for="(item, index) in subjectList"
                  :key="index"
                >
                  <div class="vx-col w-full mt-4 md:mt-0 md:w-1/2">
                    <vs-input
                      class="w-full mb-4"
                      label="Subject"
                      placeholder="Subject"
                      name="campaignSubject"
                      v-model="item.Subject_Test.Subject"
                    />
                    <vs-input
                      class="w-full"
                      label="Preview Text - Preheader"
                      placeholder="Preheader"
                      v-model="item.Subject_Test.Preview_Text"
                      name="previewText"
                    />
                  </div>
                  <div class="vx-col w-full md:mt-0 md:w-1/2">
                    <div class="vx-row text-center pt-3">
                      <div class="vx-col sm:w-1/2">
                        <vue-apex-charts
                          type="radialBar"
                          height="100"
                          :options="item.Open_Rate_Chart.chartOptions"
                          :series="item.Open_Rate_Chart.series"
                        ></vue-apex-charts>
                        <h4>Open Rate</h4>
                      </div>
                      <div class="vx-col sm:w-1/2">
                        <vue-apex-charts
                          type="radialBar"
                          height="100"
                          :options="item.Click_Rate_Chart.chartOptions"
                          :series="item.Click_Rate_Chart.series"
                        ></vue-apex-charts>
                        <h4>Click Rate</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vs-tab>
          <!-- Activity -->
          <vs-tab label="Activity" icon-pack="feather" icon="icon-activity">
            <div class="tab-text">
              <!-- Common info -->
              <div class="customers-info mt-5">
                <h2>
                  {{ viewReportDetails.Campaign_Name }}
                  <span>{{ viewReportDetails.Contact_Count }}</span>
                </h2>
                <div class="newslatter-data">
                  <ul>
                    <li>Subject: {{ viewReportDetails.Subject }}</li>
                    <li>Segment: {{ viewReportDetails.Segment }}</li>
                    <li>Delivered: {{ viewReportDetails.Date_Sent_Long | templatEditedDateTime }}</li>
                  </ul>
                  <vs-button
                    class="small-btn bg-actionbutton"
                    type="filled"
                    @click="viewEmailDetails(viewReportDetails.Campaign_Id)"
                    >{{ LabelConstant.buttonLabelViewEmail }}</vs-button
                  >
                </div>
              </div>
              <!-- Contacts table -->
              <div class="contacts-info">
                <div class="contact-table">
                  <div class="filter">
                    <div class="filter-option">
                      <v-select
                        label="filterName"
                        placeholder="Filter By sent To"
                        :options="filterByOption"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="filterBy"
                      />
                      <v-select
                        v-if="
                          filterBy &&
                          filterBy.filterName == 'Clicked specific Link'
                        "
                        label="Link_Url"
                        placeholder="Select Link"
                        v-model="linkSelected"
                        :options="linksummaryData"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        @input="getFilterContact(viewReportDetails.Campaign_Id)"
                      />
                    </div>
                    <vs-button
                      radius
                      type="filled"
                      class="bg-actionbutton small-btn"
                       @click="gridOptions.api.exportDataAsCsv()"
                      >{{ LabelConstant.buttonLabelExportContact }}</vs-button
                    >
                  </div>
                  <div class="float-left w-full">
                    <div class="flex flex-wrap justify-between items-center w-full">
                      <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
                          <vs-dropdown vs-trigger-click class="cursor-pointer">
                              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                                  <span class="mr-2">
                                    {{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ filterContacts.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : filterContacts.length }} of {{ filterContacts.length }}
                                  </span>
                                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                              </div>
                              <vs-dropdown-menu>
                                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(5)">
                                      <span>5</span>
                                  </vs-dropdown-item>
                                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                                      <span>10</span>
                                  </vs-dropdown-item>
                                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                                      <span>20</span>
                                  </vs-dropdown-item>
                                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                                      <span>25</span>
                                  </vs-dropdown-item>
                                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                                      <span>50</span>
                                  </vs-dropdown-item>
                                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                                      <span>100</span>
                                  </vs-dropdown-item>
                                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(200)">
                                      <span>200</span>
                                  </vs-dropdown-item>
                                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(300)">
                                      <span>300</span>
                                  </vs-dropdown-item>
                                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(400)">
                                      <span>400</span>
                                  </vs-dropdown-item>
                                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(500)">
                                      <span>500</span>
                                  </vs-dropdown-item>
                              </vs-dropdown-menu>
                          </vs-dropdown>
                      </div>
                      <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
                          <vs-input class="mb-4 md:mb-0 mr-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />                
                      </div>
                    </div>
                    <!-- <div class="table-row-select">
                      <vs-select
                        label="Show Entries"
                        v-model="perPage"
                        class="show-select select-connection"
                        autocomplete
                      >
                        <vs-select-item
                          :key="index"
                          :value="item.value"
                          :text="item.text"
                          v-for="(item, index) in recordList"
                        />
                      </vs-select>
                    </div> -->
                    <ag-grid-vue
                      ref="agGridTable"
                      :gridOptions="gridOptions"
                      class="ag-theme-material w-100 my-4 ag-grid-table"
                      :columnDefs="columnDefs"
                      :defaultColDef="defaultColDef"
                      :rowData="filterContacts"
                      rowSelection="multiple"
                      colResizeDefault="shift"
                      :animateRows="true"
                      :floatingFilter="true"
                      :pagination="true"
                      :paginationPageSize="5"
                      :suppressPaginationPanel="true"
                      :frameworkComponents="frameworkComponents"
                      :context="context"
                      :gridReady="onGridReady()"
                      :enableRtl="$vs.rtl"
                    >
                    </ag-grid-vue>
                     <vs-pagination class="ag-grid-pagination-area" :total="totalPages" :max="maxPageNumbers" v-model="currentPage"/>
                    <!-- <vs-table
                      :data="filterContacts"
                      search
                      v-model="activitylist"
                      multiple
                      max-items="5"
                      pagination
                    >
                      <template slot="thead">
                        <vs-th
                          sort-key="emailAddress"
                          class="sticky-item-1 sticky-item"
                          >Email Address</vs-th
                        >
                        <vs-th
                          sort-key="company"
                          class="sticky-item-2 sticky-item"
                          >Company</vs-th
                        >
                        <vs-th sort-key="firstName">First Name</vs-th>
                        <vs-th sort-key="lastName">Last Name</vs-th>
                        <vs-th sort-key="address">Address</vs-th>
                        <vs-th sort-key="phone">Phone</vs-th>
                        <vs-th sort-key="tags">Tags</vs-th>
                        <vs-th sort-key="emailStatus">Email Status</vs-th>
                        <vs-th sort-key="source">Source</vs-th>
                        <vs-th sort-key="dateCreated">Date Created</vs-th>
                        <vs-th sort-key="dateChanged">Date Changed</vs-th>
                      </template>
                      <template slot-scope="{ data }">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                          <vs-td
                            class="sticky-item-1 sticky-item"
                            :data="tr.Email_Address"
                            >{{ tr.Email_Address }}</vs-td
                          >
                          <vs-td
                            class="sticky-item-2 sticky-item"
                            :data="tr.Company"
                            >{{ tr.Company }}</vs-td
                          >
                          <vs-td :data="tr.First_Name">{{
                            tr.First_Name
                          }}</vs-td>
                          <vs-td :data="tr.Last_Name">{{
                            tr.Last_Name
                          }}</vs-td>
                          <vs-td :data="tr.Address1">{{
                            tr.Address1
                          }}</vs-td>
                          <vs-td :data="tr.Phone">{{ tr.Phone }}</vs-td>
                          <vs-td :data="tr.tags">
                            <vs-chip color="success">customer</vs-chip>
                            <vs-chip color="primary">Sales</vs-chip>
                          </vs-td>
                          <vs-td :data="tr.Email_Status">{{
                            tr.Email_Status
                          }}</vs-td>
                          <vs-td :data="tr.Source">{{ tr.Source }}</vs-td>
                          <vs-td :data="tr.Date_Created">{{
                            tr.Date_Created
                          }}</vs-td>
                          <vs-td :data="tr.Date_Changed">{{
                            tr.Date_Changed
                          }}</vs-td>
                        </vs-tr>
                      </template>
                    </vs-table> -->
                    <!-- <p class="table-data-entries-details">Showing of entries</p>
                    <p class="table-data-entries-details">
                      Showing 1 to of entries (filtered from entries)
                    </p> -->
                  </div>
                </div>
              </div>
            </div>
          </vs-tab>
          <!-- Link Summary -->
          <vs-tab label="Link Summary" icon-pack="feather" icon="icon-link">
            <!-- URL Table -->
            <div class="tab-text mt-5">
              <vs-table class="summary-table" :data="linksummaryData" search>
                <template slot="thead">
                  <vs-th sort-key="url">URL</vs-th>
                  <vs-th sort-key="totalclicks" width="120" align="right">Total Clicks</vs-th>
                  <vs-th sort-key="uniqueclicks" width="150" align="right">Unique Clicks</vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="tr.Link_Url">{{ tr.Link_Url }}</vs-td>
                    <vs-td align="right" :data="tr.Click_Count">{{ tr.Click_Count + "(" + tr.Click_Rate + "%)" }}</vs-td>
                    <vs-td align="right" :data="tr.Unique_Click_Count">{{ tr.Unique_Click_Count + "(" + tr.Unique_Click_Rate + "%)" }}</vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
      <div class="float-right mt-6 mb-8">
        <vs-button
          @click="closeViewReport()"
          color="grey"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelClose }}</vs-button
        >
      </div>
      <!-- View Email Template Design -->
      <vs-popup
        class="tag-info"
        title="View Email Template"
        :active.sync="viewEmailTemplate"
      >
        <template>
          <div class="vx-row mb-3">
            <div class="vx-col input-text w-full">
              <div v-if="emailThumbnail">
                <img
                  class="mail-sub-img"
                  :src="'data:image/png;base64, ' + emailThumbnail"
                />
              </div>
              <div class="vx-row">
                <span class="sub-text big-text">{{ emailText }}</span>
              </div>
            </div>
          </div>
        </template>
        <div class="float-right mt-6 mb-8">
          <vs-button
            color="grey"
            @click="cancelViewEmailDetails()"
            type="border"
            class="ml-4 grey-btn"
            >{{ LabelConstant.buttonLabelCancel }}</vs-button
          >
        </div>
      </vs-popup>
    </vs-popup>
  </div>
</template>

<!-- Script -->

<script>
var joinAddresses = function (params) {
  return params.data.Address1 + ", " + params.data.Address2;
};
var joinTags = function (params) {
  let str = "";
  params.data.Tags.forEach((el) => {
    str += '<span class="badge badge-success ">' + el.Tag_Name + "</span>";
  });
  return str;
};

import VxCard from "@/components/vx-card/VxCard";
import VueApexCharts from "vue-apexcharts";
import DashboardSingleCard from "../../components/dashboard/SingleCard";
import SingleCard from "../../components/SingleCard";
import moment from "moment";
import vSelect from "vue-select";
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import ContactDetailsLink from "@/components/email-marketing/ContactDetailsLink";
export default {
  components: {
    vSelect,
    VxCard,
    VueApexCharts,
    DashboardSingleCard,
    AgGridVue,
    SingleCard,
    ContactDetailsLink,
  },
  data() {
    let uid = this.$route.params.id;
    let VVtoken = localStorage.getItem("VVtoken");
    let token = JSON.parse(VVtoken);
    return {
      tabActive:0,
      searchQuery:null,
      maxPageNumbers:7,
      context: null,
      frameworkComponents: null,
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "Email Address",
          field: "Email_Address",
          width: 200,
          pinned: "left",
          filter: true,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true,
          cellRenderer: "contactDetailsLink",
        },
        {
          headerName: "Company",
          field: "Company",
          filter: true,
          width: 250,
        },
        {
          headerName: "First Name",
          field: "First_Name",
          filter: true,
          width: 250,
        },
        {
          headerName: "Last Name",
          field: "Last_Name",
          filter: true,
          width: 250,
        },
        {
          headerName: "Address",
          valueGetter: joinAddresses,
          filter: true,
          width: 350,
        },
        {
          headerName: "Tags",
          field: "Tags",
          filter: false,
          width: 350,
          cellRenderer: joinTags,
        },
        {
          headerName: "Email Status",
          field: "Email_Status",
          filter: true,
          width: 350,
        },
      ],

      filterContacts: [],
      limitedTopLinks: 5,
      limitedSubcribers: 5,
      senderList: [],
      subjectList: [],
      viewEmailTemplate: false,
      emailThumbnail: null,
      emailText: "",
      lineChartSimple: {
        series: [
          {
            name: "Opens",
            data: [],
          },
          {
            name: "Clicks",
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            height: 350,
            zoom: {
              enabled: false,
            },
          },
          colors: ["#044d80", "#ecb940"],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
          },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"],
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: [],
          },
        },
      },
      radialBarChart: {
        series: [44],
        chartOptions: {
          colors: ["#7AC546"],
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "22px",
                  show: false,
                },
                value: {
                  fontSize: "16px",
                  offsetY: 6,
                },
                total: {
                  show: false,
                },
              },
            },
          },
        },
      },
      filterBy: null,
      filterByOption: [
        {
          filterName: "Sent To",
        },
        {
          filterName: "Opened",
        },
        {
          filterName: "Didn't Open",
        },
        {
          filterName: "Clicked",
        },
        {
          filterName: "Bonuced",
        },
        {
          filterName: "Unsubscribed",
        },
        {
          filterName: "Complained",
        },
        {
          filterName: "Clicked specific Link",
        },
      ],
      /* Link Summary */
      // linksummary: [],
      linkSelected: null,
      linksummaryData: [],
      perPage: 5,
      recordList: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      activitylist: [],
      activityListData: [
        {
          emailAddress: "Test@test.com",
          company: "Test Company",
          firstName: "Himani",
          lastName: "Darji",
          address: "123",
          phone: "123456789",
          emailStatus: "unsubscribed",
          source: "hildegard.org",
          dateCreated: "24-01-2020",
          dateChanged: "24-01-2020",
        },
        {
          emailAddress: "Test@test.com",
          company: "Test Company",
          firstName: "Himani",
          lastName: "Darji",
          address: "123",
          phone: "123456789",
          emailStatus: "unsubscribed",
          source: "hildegard.org",
          dateCreated: "24-01-2020",
          dateChanged: "24-01-2020",
        },
        {
          emailAddress: "Test@test.com",
          company: "Test Company",
          firstName: "Himani",
          lastName: "Darji",
          address: "123",
          phone: "123456789",
          emailStatus: "unsubscribed",
          source: "hildegard.org",
          dateCreated: "24-01-2020",
          dateChanged: "24-01-2020",
        },
      ],
      viewReportDetails: null,
      CampaignViewReportActive: false,
      campaigns: [],
      tags: [],
      emailsSent: {
        StatChangePercent: 0,
        StatEndValue: 0,
        StatName: null,
        StatStartValue: 0,
      },
      emailsDelivered: {
        StatChangePercent: 0,
        StatEndValue: 0,
        StatName: null,
        StatStartValue: 0,
      },
      emailsOpened: {
        StatChangePercent: 0,
        StatEndValue: 0,
        StatName: null,
        StatStartValue: 0,
      },
      emailsClicked: {
        StatChangePercent: 0,
        StatEndValue: 0,
        StatName: null,
        StatStartValue: 0,
      },
      contacts: {
        StatChangePercent: 0,
        StatEndValue: 0,
        StatName: null,
        StatStartValue: 0,
      },
      newContacts: {
        StatChangePercent: 0,
        StatEndValue: 0,
        StatName: null,
        StatStartValue: 0,
      },
      subscribed: {
        StatChangePercent: 0,
        StatEndValue: 0,
        StatName: null,
        StatStartValue: 0,
      },
      unSubscribed: {
        StatChangePercent: 0,
        StatEndValue: 0,
        StatName: null,
        StatStartValue: 0,
      },
      emailStats: {
        series: [
          {
            name: "Total Emails",
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            height: 350,
            zoom: {
              enabled: false,
            },
          },
          colors: [token[uid].themeDetail.tempChartColor1],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
          },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"],
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: [],
          },
        },
      },
      contactStats: {
        series: [
          {
            name: "Total Contacts",
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            height: 350,
            zoom: {
              enabled: false,
            },
          },
          colors: [token[uid].themeDetail.tempChartColor1],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
          },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"],
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: [],
          },
        },
      },
    };
  },
  watch: {
    filterBy(val) {
      if (!val && this.viewReportDetails) {
        this.linkSelected = null;
        this.getFilterContact(this.viewReportDetails.Campaign_Id);
      } else if (val && val.filterName != "Clicked specific Link") {
        this.getFilterContact(this.viewReportDetails.Campaign_Id);
      }
    },
  },
   computed: {
    paginationPageSize() {
        if(this.gridOptions.api) return this.gridOptions.api.paginationGetPageSize()
        else return 1
    },
    totalPages() {
        if(this.gridOptions.api) return this.gridOptions.api.paginationGetTotalPages()
        else return 1
    },
    currentPage: {
      get() {
        if(this.gridOptions.api) return this.gridOptions.api.paginationGetCurrentPage() + 1
        else return 1
      },
      set(val) {
          this.gridOptions.api.paginationGoToPage(val - 1)
      }
    },
  },
  created() {
    this.getCampaign();
    this.getTags();
    this.getEmailSent();
    this.getEmailDelivered();
    this.getEmailOpened();
    this.getEmailClicked();
    this.getContacts();
    this.getNewContacts();
    this.getSubcribed();
    this.getUnSubcribed();
    this.getEmailStatLast6Month();
    this.getContactStatLast6Month();
  },
  beforeMount() {
    this.context = { componentParent: this };
    this.frameworkComponents = {
      contactDetailsLink: ContactDetailsLink,
    };
  },
  methods: {
    changeTab(val){
      this.tabActive = 1;
      this.filterBy = { filterName : val };
    },
    onGridReady() {
      this.gridApi = this.gridOptions.api;
      if (this.$vs.rtl) {
        const header = this.$refs.agGridTable.$el.querySelector(
          ".ag-header-container"
        );
        header.style.left =
          "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
      }
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val)
    },
    showMoreTopLinks() {
      if (this.limitedTopLinks == 5) {
        this.limitedTopLinks = this.viewReportDetails.Top_Links_Clicked.length;
      } else {
        this.limitedTopLinks = 5;
      }
    },
    showMoreSubcriber() {
      if (this.limitedSubcribers == 5) {
        this.limitedSubcribers = this.viewReportDetails.Subscribers_With_Most_Open.length;
      } else {
        this.limitedSubcribers = 5;
      }
    },
    // get list of the campaign
    getCampaign() {
      this.$vs.loading();
      this.axios
        .get("/ws/EmailMarketingDashboard/GetLatestCampaignSummary")
        .then((response) => {
          let data = response.data;
          data.sort(this.GetSortOrder("Campaign_Id", "DESC"));
          this.campaigns = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    // get list of the tags
    getTags() {
      this.$vs.loading();
      this.axios
        .get("/ws/EmailMarketingDashboard/GetLatestTagSummary")
        .then((response) => {
          let data = response.data;
          this.tags = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    // get email sent stats api
    getEmailSent() {
      this.$vs.loading();
      this.axios
        .get("/ws/EmailMarketingDashboard/GetEmailStatsTotalEmailsSent")
        .then((response) => {
          let data = response.data;
          this.emailsSent.StatChangePercent = data.StatChangePercent;
          this.emailsSent.StatEndValue = data.StatEndValue;
          this.emailsSent.StatName = data.StatName;
          this.emailsSent.StatStartValue = data.StatStartValue;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    // get email delivered stats api
    getEmailDelivered() {
      this.$vs.loading();
      this.axios
        .get("/ws/EmailMarketingDashboard/GetEmailStatsTotalEmailsDelivered")
        .then((response) => {
          let data = response.data;
          this.emailsDelivered.StatChangePercent = data.StatChangePercent;
          this.emailsDelivered.StatEndValue = data.StatEndValue;
          this.emailsDelivered.StatName = data.StatName;
          this.emailsDelivered.StatStartValue = data.StatStartValue;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    // get email opened stats api
    getEmailOpened() {
      this.$vs.loading();
      this.axios
        .get("/ws/EmailMarketingDashboard/GetEmailStatsTotalEmailsDelivered")
        // .get("/ws/EmailMarketingDashboard/GetEmailStatsTotalEmailsOpened")
        .then((response) => {
          let data = response.data;
          this.emailsOpened.StatChangePercent = data.StatChangePercent;
          this.emailsOpened.StatEndValue = data.StatEndValue;
          this.emailsOpened.StatName = data.StatName;
          this.emailsOpened.StatStartValue = data.StatStartValue;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    // get email clicked stats api
    getEmailClicked() {
      this.$vs.loading();
      this.axios
        .get("/ws/EmailMarketingDashboard/GetEmailStatsTotalEmailsClicked")
        .then((response) => {
          let data = response.data;
          this.emailsClicked.StatChangePercent = data.StatChangePercent;
          this.emailsClicked.StatEndValue = data.StatEndValue;
          this.emailsClicked.StatName = data.StatName;
          this.emailsClicked.StatStartValue = data.StatStartValue;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    cancelViewEmailDetails() {
      this.emailThumbnail = null;
      this.viewEmailTemplate = false;
      this.emailText = "";
    },
    // get contacts stats api
    getContacts() {
      this.$vs.loading();
      this.axios
        .get("/ws/EmailMarketingDashboard/GetEmailStatsTotalContacts")
        .then((response) => {
          let data = response.data;
          this.contacts.StatChangePercent = data.StatChangePercent;
          this.contacts.StatEndValue = data.StatEndValue;
          this.contacts.StatName = data.StatName;
          this.contacts.StatStartValue = data.StatStartValue;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    // get new contacts stats api
    getNewContacts() {
      this.$vs.loading();
      this.axios
        .get("/ws/EmailMarketingDashboard/GetEmailStatsTotalContacts")
        // .get("/ws/EmailMarketingDashboard/GetEmailStatsTotalNewContacts")
        .then((response) => {
          let data = response.data;
          this.newContacts.StatChangePercent = data.StatChangePercent;
          this.newContacts.StatEndValue = data.StatEndValue;
          this.newContacts.StatName = data.StatName;
          this.newContacts.StatStartValue = data.StatStartValue;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    // get subcribed contacts stats api
    getSubcribed() {
      this.$vs.loading();
      this.axios
        .get("/ws/EmailMarketingDashboard/GetEmailStatsTotalSubscribedContacts")
        .then((response) => {
          let data = response.data;
          this.subscribed.StatChangePercent = data.StatChangePercent;
          this.subscribed.StatEndValue = data.StatEndValue;
          this.subscribed.StatName = data.StatName;
          this.subscribed.StatStartValue = data.StatStartValue;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    // get unsubcribed contacts stats api
    getUnSubcribed() {
      this.$vs.loading();
      this.axios
        .get("/ws/EmailMarketingDashboard/GetEmailStatsTotalUnsubscribedContacts")
        .then((response) => {
          let data = response.data;
          this.unSubscribed.StatChangePercent = data.StatChangePercent;
          this.unSubscribed.StatEndValue = data.StatEndValue;
          this.unSubscribed.StatName = data.StatName;
          this.unSubscribed.StatStartValue = data.StatStartValue;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    // get email stats of last 6 months
    getEmailStatLast6Month() {
      this.$vs.loading();
      this.axios
        .get("/ws/EmailMarketingDashboard/GetEmailStatsTotalEmailSentLast6Months")
        .then((response) => {
          let data = response.data;
          let x = [];
          let FirstGroup = [];

          data.forEach((data) => {
            x.push(data.x);
            FirstGroup.push(data.FirstGroup);
          });

          if (!FirstGroup.length > 0) {
            FirstGroup = [0];
          }

          this.emailStats.series = [
            {
              name: "Total Emails",
              data: FirstGroup,
            },
          ];

          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    // get contacts stats api chart of 6 months
    getContactStatLast6Month() {
      this.$vs.loading();
      this.axios
        .get("/ws/EmailMarketingDashboard/GetEmailStatsTotalContactsLast6Months")
        .then((response) => {
          let data = response.data;
          let x = [];
          let FirstGroup = [];

          data.forEach((data) => {
            x.push(data.x);
            FirstGroup.push(data.FirstGroup);
          });

          if (!FirstGroup.length > 0) {
            FirstGroup = [0];
          }

          this.contactStats.series = [
            {
              name: "Total Emails",
              data: FirstGroup,
            },
          ];
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    viewEmailDetails(id) {
      this.$vs.loading();
      this.axios
        .get("ws/EmailMarketingCampaign/GetCampaign?Id=" + id)
        .then((response) => {
          let data = response.data;
          this.selectedCampaignDetail = data;
          if (this.selectedCampaignDetail.Content) {
            if (this.selectedCampaignDetail.Thumbnail_Image) {
              this.emailThumbnail = this.selectedCampaignDetail.Thumbnail_Image;
            } else {
              this.getBeeFreeGetThumbnail(this.selectedCampaignDetail.Content,null,-1);
            }
          } else {
            this.emailText = "Email template not designed";
          }
          this.viewEmailTemplate = true;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    getCampaignOverviewReport(id) {
      this.$vs.loading();
      this.axios
        .get("/ws/EmailMarketingCampaignReporting/GetCampaignOverviewReport?Id=" +id)
        .then((response) => {
          let data = response.data;
          this.viewReportDetails = data;
          let x = [];
          let FirstGroup = [];
          let SecondGroup = [];
          data.Twentyfour_Hour_Performance.forEach((e) => {
            x.push(e.x);
            FirstGroup.push(e.FirstGroup);
            SecondGroup.push(e.SecondGroup);
          });
          this.lineChartSimple.series = [
            {
              name: "Opens",
              data: FirstGroup,
            },
            {
              name: "Clicks",
              data: SecondGroup,
            },
          ];
          this.lineChartSimple.chartOptions = {
            ...this.lineChartSimple.chartOptions,
            ...{
              xaxis: {
                categories: x,
                labels: {
                  hideOverlappingLabels: true,
                  showDuplicates: false,
                },
              },
            },
          };
          if (data.Campaign_Type == "AB") {
            this.getABSenderResult(id);
            this.getABSubjectResult(id);
          }
          //this.getActivityData();
          this.getLinkSummaryData(id);
          this.getFilterContact(id);
          this.CampaignViewReportActive = true;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    getABSenderResult(id) {
      this.$vs.loading();
      this.axios
        .get("/ws/EmailMarketingCampaignReporting/CampaignABTestSenderReport?Id=" +id)
        .then((response) => {
          let data = response.data;
          if (data.length > 0) {
            data.forEach((e) => {
              e.Open_Rate_Chart = {
                series: [e.Open_Rate == "NaN" ? 0 : e.Open_Rate],
                chartOptions: {
                  colors: ["#7AC546"],
                  plotOptions: {
                    radialBar: {
                      dataLabels: {
                        name: {
                          fontSize: "22px",
                          show: false,
                        },
                        value: {
                          fontSize: "16px",
                          offsetY: 6,
                        },
                        total: {
                          show: false,
                        },
                      },
                    },
                  },
                },
              };
              e.Click_Rate_Chart = {
                series: [e.Click_Rate == "NaN" ? 0 : e.Click_Rate],
                chartOptions: {
                  colors: ["#7AC546"],
                  plotOptions: {
                    radialBar: {
                      dataLabels: {
                        name: {
                          fontSize: "22px",
                          show: false,
                        },
                        value: {
                          fontSize: "16px",
                          offsetY: 6,
                        },
                        total: {
                          show: false,
                        },
                      },
                    },
                  },
                },
              };
            });
            this.senderList = data;
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    getABSubjectResult(id) {
      this.$vs.loading();
      this.axios
        .get("/ws/EmailMarketingCampaignReporting/CampaignABTestSubjectReport?Id=" +id)
        .then((response) => {
          let data = response.data;
          if (data.length > 0) {
            data.forEach((e) => {
              e.Open_Rate_Chart = {
                series: [e.Open_Rate == "NaN" ? 0 : e.Open_Rate],
                chartOptions: {
                  colors: ["#7AC546"],
                  plotOptions: {
                    radialBar: {
                      dataLabels: {
                        name: {
                          fontSize: "22px",
                          show: false,
                        },
                        value: {
                          fontSize: "16px",
                          offsetY: 6,
                        },
                        total: {
                          show: false,
                        },
                      },
                    },
                  },
                },
              };
              e.Click_Rate_Chart = {
                series: [e.Click_Rate == "NaN" ? 0 : e.Click_Rate],
                chartOptions: {
                  colors: ["#7AC546"],
                  plotOptions: {
                    radialBar: {
                      dataLabels: {
                        name: {
                          fontSize: "22px",
                          show: false,
                        },
                        value: {
                          fontSize: "16px",
                          offsetY: 6,
                        },
                        total: {
                          show: false,
                        },
                      },
                    },
                  },
                },
              };
            });
            this.subjectList = data;
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    getLinkSummaryData(id) {
      this.$vs.loading();
      this.axios
        .get("ws/EmailMarketingCampaignReporting/CampaignLinkSummaryReport?Id=" +id)
        .then((response) => {
          let data = response.data;
          this.linksummaryData = data.Link_Summary;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    getFilterContact(id) {
      this.$vs.loading();
      let input = {
        Campaign_ID: id,
        Fitler_Type: this.filterBy ? this.filterBy.filterName : "",
        Link_ID: this.linkSelected ? this.linkSelected.Link_Id : 0,
      };
      //let input = {
      //   Campaign_ID: 30,
      //   Fitler_Type: "Opened",
      //   Link_ID: 0,
      // };
      this.axios
        .post("/ws/EmailMarketingCampaignReporting/FilterContacts", input)
        .then((res) => {
          let data = res.data;
          this.filterContacts = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    /* getActivityData(){
      this.$vs.loading();
      this.axios
        .get("ws/EmailMarketingCampaignReporting/CampaignActivityReport?Id=30")
        .then((response) => {
          let data = response.data;
          console.log(data);
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },*/
    closeViewReport() {
      this.filterBy = null;
      this.limitedSubcribers = 5;
      this.limitedTopLinks = 5;
      this.CampaignViewReportActive = false;
      this.viewReportDetails = null;
      this.subjectList = [];
      this.senderList = [];
      this.linksummaryData = [];
      this.filterContacts = [];
      this.linkSelected = null;
    },
    redirectToContactsWithSelectedTag(id) {
      this.$router.push({
        name: "Contacts",
        params: { tagSelected: id },
      });
    },
  },
  filters: {
    templatEditedDateTime: function (value) {
      return moment(value).format("MMMM DD, YYYY HH:mm A");
    },
  },
};
</script>
